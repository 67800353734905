<!-- template -->
<template>
  <div id="app">
    <div class="container">
      <div class="row">
        <div class="col-md-6 d-none d-md-block">
          <carousel />

          <h5 class="Copyright-2021-Dat">
            Copyright © 2021 Data Poem All rights reserved. Privacy and Legal
            Policies
          </h5>
        </div>

        <div class="col-md-6">
          <div class="auth-wrapper py-0 py-md-5">
            <div class="auth-inner content-center">
              <div class="row">
              <div class="w-100 content-center mb-5">
              <img
                src="../assets/bitmap.png"
                srcset="../assets/bitmap@2x.png 2x, ../assets/bitmap@3x.png 3x"
                class="Bitmap"
              />
              </div>
              <div class="w-100">
              <div class=" text-center">
                <img src="../assets/complete.png" />
              </div>

              <div class="Forgot-your-password- center-block">
                You are all set!
              </div>

              <div class="New-password-has-bee center-block">
                {{message}}
              </div>

              <div class="text-center mt-5 pt-5 d-flex justify-center">
                <w-button
                  :buttonText="'Login'"
                  class="w-50"
                  @buttonClicked="$router.push('/')"
                >
                </w-button>
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { formMixin } from "@/mixins/form-mixin.js";
import Carousel from "@/components/Login/Carousel.vue";
import Button from "@/components/Profile/Button.vue";

export default {
  name: "PasswordResetComplete",

  mixins: [validationMixin, formMixin],

  components: {
    Carousel,
    "w-button": Button,
  },
  data(){
    return {
      message:""
    }
  },
  created(){
    if(this.$route.params.message !== undefined){
      this.message = this.$route.params.message
    }else{
      this.message = "New password has been reset successfully"
    }
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
}

body,
html,
#app,
#root,
.auth-wrapper {
  width: 100%;
  height: auto;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 546px;
  height: 760px;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #dadee4;
  background-color: #ffffff;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.Bitmap {
  height: 170px;
  object-fit: contain;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.Group-10 {
  margin-top: 270px;
  object-fit: contain;
}

.Group-10 img {
  width: 99px;
  height: 99px;
}

.Forgot-your-password- {
  width: 319px;
  height: 50px;
  font-family: ProximaNovaBold;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #222a37;
  margin-top: 16px;
}

.New-password-has-bee {
  height: 24px;
  margin: 3px 0 0;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #9aafd4;
}

::placeholder {
  color: #9aafd4;
  opacity: 1; /* Firefox */
}

.password {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 485px;
  right: 450px;
  object-fit: contain;
  background-color: #f2f5fa;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  margin-top: 30px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.isi {
  width: 190px;
  height: 60px;
  margin: 0 0 0 12px;
  object-fit: contain;
  opacity: 0.5;
}

.Copyright-2021-Dat {
  width: 434px;
  height: 16px;
  font-family: ProximaNovaBold;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  position: absolute;
  left: -30px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #9aafd4;
}

/* form inputs */
.c-form-input {
  width: 100% !important;
  height: 60px !important;
  margin: 40px 1px 6px 0 !important;
  padding: 18px 18px 18px 16px !important;
  border-radius: 3px !important;
  background-color: #eff5ff !important;
  object-fit: contain;
  background: url(../assets/lock-password-line.png) no-repeat scroll 15px 15px;
  background-size: 24px;
  padding-left: 50px !important;
  font-size: 16px;
}

.c-form-input.is-invalid {
  background-color: #f8d8d8 !important;
}

.form-control:focus.is-invalid {
  border-color: #f8d8d8 !important;
}
</style>
